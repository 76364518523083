
* {
  margin: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}

body {
  background-color: rgb(0, 15, 62);
}


.App {
  font-size: 16px;
  line-height: 1;

  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.landing-page-title {
  color: rgb(255, 255, 255);
  font-size: 8rem;
  font-weight: 600;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.876);
  user-select: none;
}


